<template>
  <div class="d-flex flex-row align-center">
    <date-filters
      v-model="computedFrom"
      low
      :label="$t('t.Date')"
      class="value"
      ref="input"
      clearable
      :flat="flat"
      :hide-prepend-icon="hidePrependIcon"
      :max="max"
      :min="min"
      :prepend-icon="prependIcon"
      :origin="origin"
      :solo="solo"
      :rules="rules"
      :readonly="readonly"
    />
    <span class="mx-4">{{$t('t.And')}}</span>
    <date-filters
      v-model="computedTo"
      low
      :label="$t('t.Date')"
      class="value"
      ref="input"
      clearable
      :flat="flat"
      :hide-prepend-icon="hidePrependIcon"
      :max="max"
      :min="min"
      :prepend-icon="prependIcon"
      :origin="origin"
      :solo="solo"
      :rules="rules"
      :readonly="readonly"
    />
  </div>
</template>
<script>
export default {
  components: {
    DateFilters: () => import('../../../date-filters')
  },
  computed: {
    computedFrom: {
      get () { return this.from },
      set (v) {
        this.from = this.lodash.cloneDeep(v)
        this.dataDocumentEmit()
      }
    },
    computedTo: {
      get () { return this.to },
      set (v) {
        this.to = this.lodash.cloneDeep(v)
        this.dataDocumentEmit()
      }
    },
    textFieldType () {
      return this.isNumber ? 'number' : 'text'
    }
  },
  data () {
    return {
      from: null,
      to: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      const doc = [this.from, this.to]

      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    dense: Boolean,
    disabled: Boolean,
    document: Array,
    flat: Boolean,
    hidePrependIcon: Boolean,
    isNumber: Boolean,
    origin: String,
    max: String,
    min: String,
    prependIcon: String,
    rules: Array,
    solo: Boolean,
    targetDocTy: String,
    readonly: Boolean
  },
  watch: {
    document: {
      handler (v) {
        this.from = v?.[0] ? this.lodash.cloneDeep(v[0]) : null
        this.to = v?.[1] ? this.lodash.cloneDeep(v[1]) : null
        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
